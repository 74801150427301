import router from "@/config/routes"
import { auth0 } from "@/lib/auth0";
import store from "@/store"

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = await auth0.isAuthenticated();

  // queryパラメータにlangが含まれていたらpage_language_idをupdateする
  if (to.query['lang']) {
    store.commit("setPageLang", to.query['lang']);
  }
  // userのデータを取得
  await store.dispatch("user/update");

  // ログイン済みで初期化していない人は強制的にInitializeProfilePageへ
  // public, limited は除外
  // ログイン済 & Initializedされていない && (Initializedされていなくても許可されるものじゃない)
  const isInitialized = store.state.user.is_initialized;
  const isJTJSignedUp = store.state.user.is_signed_up;
  const isAllowedWithoutInitialized =
    ["InitializeProfilePage", "LogOutPage", "SignUpPage"].includes(to.name ?? '')
    || ["public", "limited", "own_application"].includes(to.meta.auth);

  if (isAuthenticated && !isAllowedWithoutInitialized) {
    if (!isJTJSignedUp) {
      return next({ name: "SignUpPage", query: { redirect: to.path } });
    }

    if (!isInitialized) {
      return next({ name: "InitializeProfilePage", query: { redirect: to.path } });
    }
  }

  /**
   * @param {import("vue-router").Route} to
   * @param {import("vue-router").Route} from
   */
  const nextOrNextWithRedirect = (to, from) => {
    if (shouldAddRedirectPath(to, from)) {
      return next({ name: to.name ?? '', query: { ...to.query, redirect: getRedirectPath(to, from) } })
    } else {
      return next()
    }
  }


  // 通常時
  switch (to.meta.auth) {

    case "public":
      return nextOrNextWithRedirect(to, from)

    case "log_in":
      if (isJTJSignedUp) return nextOrNextWithRedirect(to, from)
      else return next({ name: "SignUpPage", query: { redirect: to.path } })

    // initializeProfile していなくても閲覧できる権限
    case "limited":
      if (isJTJSignedUp) return nextOrNextWithRedirect(to, from)
      else return next({ name: "SignUpPage", query: { redirect: to.path } })

    case "not_log_in":
      if (!isJTJSignedUp) return nextOrNextWithRedirect(to, from)
      else return to.query.redirect ? next(to.query.redirect) : next("/recruitments");

    case "own_application":
      if (store.state.user.isOwnApplication(to.params.id)) return nextOrNextWithRedirect(to, from)
      else if (isJTJSignedUp) return to.query.redirect ? next(to.query.redirect) : next("/recruitments")
      else return next({ name: "SignUpPage", query: { redirect: to.path } })

    default:
      return nextOrNextWithRedirect(to, from)
  }
})

/**
 * @param {import("vue-router").Route} to
 * @param {import("vue-router").Route} from
 */
function shouldAddRedirectPath(to, from) {
  // toの後にredirectするべきページが存在する && もともとのtoのURLにredirect_pathのが存在しない(何か意図的にリダイレクトさせたいページが存在しない)
  // のときにredirectのqueryパラメータ追加の必要あり。
  return !!getRedirectPath(to, from) && !to.query.redirect
}

/**
 * @param {import("vue-router").Route} to
 * @param {import("vue-router").Route} from
 */
function getRedirectPath(to, from) {
  // redirect_pathはLogInや、SignUpや、InitializedProfileなど、
  // 特殊なタイミングで redirectOrPushというmixin関数で画面遷移をする時に使われる。
  // redirect_type一覧( 基本的に自身がfromのときの性質 )
  // none: redirect_pathを消す
  // take_over: redirect_pathを引き継ぐ
  // self: 自信がredirect_pathになる
  // 参考
  // https://docs.google.com/spreadsheets/d/13P9svukZVfCNOs8FZ5-EMOs_8LesaKzz0kOgCAhZDaA/edit#gid=0&range=A1:B2

  // self→take_overのとき、redirect_pathはfromのpathになる
  if (from.meta.redirect_type == "self" && to.meta.redirect_type == "take_over") {
    return from.path
  }
  // take_over→take_overのとき、redirect_pathは前のtake_overを引き継ぐことになる。
  if (from.meta.redirect_type == "take_over" && to.meta.redirect_type == "take_over") {
    return from.query.redirect
  }
  return null
}



export default router

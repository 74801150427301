import posthogJs from "posthog-js";

const key = process.env['VUE_APP_POSTHOG_KEY'];

const posthog = posthogJs.init(key);

export default {
  install(Vue, options) {
    if (!key) {
      console.warn("Posthog key not found. Posthog will not be initialized.");
      Vue.prototype.$posthog = new Proxy(
        {},
        {
          get(target, property, receiver) {
            return () => {
              console.warn(
                `Posthog method '${property}' was called, but Posthog is not initialized.`
              );
            };
          },
        }
      );
      return;
    }
    Vue.prototype.$posthog = posthog;
  },
};

import { vue } from '@/main';
import { createAuth0Client } from '@auth0/auth0-spa-js';

export const auth0Config = {
  domain: process.env['VUE_APP_AUTH0_DOMAIN'],
  clientId: process.env['VUE_APP_AUTH0_STUDENT_OLD_CLIENT_ID'],
  audience: process.env['VUE_APP_HFAPI_HOST'],
  redirectUri: '/auth0-callback',
}

if (!auth0Config.domain || !auth0Config.clientId || !auth0Config.audience) {
  throw new Error('Auth0 configuration is missing');
}

export const auth0 = await createAuth0Client({
  domain: auth0Config.domain,
  clientId: auth0Config.clientId,
  authorizationParams: {
    audience: auth0Config.audience,
  }
});

/**
 * @param {{ signUp?: boolean, returnTo?: import('vue-router').Location }} options
 * @returns {Promise<void>}
 */
export const loginWithRedirect = async ({ signUp, returnTo } = { signUp: false }) => {
  await auth0.loginWithRedirect({
    appState: { returnTo },
    authorizationParams: {
      screen_hint: signUp ? 'signup' : 'login',
      redirect_uri: new URL(`/#${auth0Config.redirectUri}`, window.location.origin).href,
    },
  });
}

export const handleRedirectCallback = async () => {
  const result = await auth0.handleRedirectCallback();

  const user = await auth0.getUser();

  if (!user) {
    throw new Error('Login failed');
  }

  if (user.email && user.email_verified) {
    // @ts-ignore
    void vue.$posthog?.identify(user.email)
  }

  return result;
}

/**
 *
 * @param {import('@auth0/auth0-spa-js').LogoutOptions} options
 */
export const logout = async (options) => {
  await auth0.logout(options);
}

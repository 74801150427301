import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import router from "./config/router";
import requireBaseComponents from "./config/requireBaseComponents"
import hfapiConfigure from "./config/hfapi"
import vuetify from '@/plugins/vuetify' // path to vuetify export
import mixin from '@/plugins/mixin'
import store from './store'
import VueGtm from "@harmony-for/vue2-gtm";
import moment from 'moment'
import i18n from "./config/i18n";
import MasterSelectField from "@/components/organisms/shared/MasterSelectField.vue";
import ValidationViewer from "@/components/molecules/ValidationViewer.vue";
import YearMonthPicker from "@/components/molecules/YearMonthPicker.vue";
import YesNoSelector from "@/components/molecules/YesNoSelector.vue";
import extensions from "@/lib/extensions"
import VueYoutube from 'vue-youtube'
import posthogPlugin from '@/plugins/posthog'


Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.component("MasterSelectField", MasterSelectField);
Vue.component("ValidationViewer", ValidationViewer);
Vue.component("YearMonthPicker", YearMonthPicker);
Vue.component("YesNoSelector", YesNoSelector);
// https://github.com/gtm-support/vue-gtm/tree/vue2-gtm
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_TRACKING_CODE, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  // queryParams: {
  //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
  //   gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
  //   gtm_preview: "env-4",
  //   gtm_cookies_win: "x",
  // },
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: "2726c7f26c", // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  vueRouterAdditionalEventData: () => {
    console.log(store.getters["gac/signUpState"]);
    return {
      "sign-up-state": store.getters["gac/signUpState"],
      "current-login-state": store.getters["gac/currentLoginState"],
    };
  },
  meta: { gtmAdditionalEventData: { routeCategory: "INFO" } },
  // ignoredViews: ["homepage"], // Don't trigger events for specified router names (optional)
  // trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});


Vue.mixin(mixin)
Vue.use(VueYoutube)

Vue.use(posthogPlugin);

window.moment = moment
Vue.prototype.moment = moment

requireBaseComponents(Vue)
hfapiConfigure()
extensions()

export const vue = new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");

if (window.Cypress) {
  // only available during E2E tests
  window.vue = vue
  window.store = store
}

<template>
  <v-app>
    <div id="app">
      <div v-if="$store.state.gac.isHfMember" style="height: 10px; z-index: 100000; position: fixed; right: 0;">
        <h4>HfMemberとして閲覧中</h4>
      </div>
      <TheSnackbar/>
      <TheHeader/>
        <v-main>
          <!-- Provides the application the proper gutter -->
          <v-container fluid>
            <!-- If using vue-router -->
              <router-view />
          </v-container>
          <div v-show="showInquiryButton" class="inquiry-btn-container text-right pr-10">
            <VBtn @click="showInquiryModal=true" class="mx-2" fab dark large color="primary">
              <VIcon dark> mdi-chat-processing </VIcon>
            </VBtn>
          </div>
          <InquiryDialog :showModal="showInquiryModal" @close="showInquiryModal=false"/>
      </v-main>
      <TheFooter />
    </div>
  </v-app>
</template>

<script>
import TheHeader from './components/organisms/TheHeader';
import TheFooter from './components/organisms/TheFooter';
import TheSnackbar from './components/organisms/TheSnackbar'
import InquiryDialog from './components/organisms/InquiryDialog'
import { LANGUAGE_ID_2_SYMBOL } from "@/config/constants.js";

export default {
  components: {
    TheHeader,
    TheFooter,
    TheSnackbar,
    InquiryDialog,
  },
  data(){
    return {
      showInquiryModal: false,
      console: console
    }
  },
  methods: {
    setGACampaign(){
      if (!this.$store.state.gac.firstCampaign.name) {
        this.$store.commit("gac/setFirstCampaign", window.location)
      }
      this.$store.commit("gac/setLatestCampaign", window.location)
    },
    setIsHfMember(){
      // 初期設定用
      if (this.$store.state.gac.isHfMember){
        this.$store.commit("gac/setIsHfMember", true);
      }
    }
  },
  computed: {
    currentLocale(){
      return LANGUAGE_ID_2_SYMBOL[this.$store.state.page_language_id]
    },
    showInquiryButton(){
      const hideInquiryButtonPageNames = ["RecruitmentShowPage"]
      return this.isSignIn && !hideInquiryButtonPageNames.isInclude(this.$route.name)
    },
  },
  watch: {
    currentLocale: {
      handler: function(){
        // I18nを合わせる
        this.$i18n.locale = this.currentLocale
        // データベースの値を合わせる
        if (this.isSignIn){
          this.$store.dispatch("user/setPageLang", { symbol: this.currentLocale });
        }
        // 今フロントエンドに持ってきているマスターデータなどを合わせる
        this.window.location.reload()
      }
    }
  },
  async created(){
    await this.updateSignInStatus()

    const urlParams = new URLSearchParams(location.search);
    const paramsPageLang = urlParams.get("lang")
    if ( !this.$store.state.page_language_id || !!paramsPageLang ){
      this.$store.commit("setPageLang", paramsPageLang || "en");
    }
    this.$i18n.locale = this.currentLocale
    this.setGACampaign()
    this.setIsHfMember()
  }
}
</script>

<style lang="scss">
  #app {
    background-color: #F6F5F5;
    p {
      white-space: pre-line;
    }
  }
  .horizontal-full {
    width: 100vw!important;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding-left: 12px;
    padding-right: 12px;
  }
  .v-card__title {
    word-break: normal !important; // word-breakがbreak-allにvuetify側でされているのでデフォルトの挙動に戻す
    overflow-wrap: break-word; //
    word-wrap: break-word;
    white-space: pre-line !important;
  }
  .inquiry-btn-container {
    position: fixed;
    right: 50px;
    bottom: 20px;
    width: 50px;
    z-index: 200;
  }
  .v-slide-group__wrapper {
      touch-action: auto !important;
  }
  .v-btn {
    text-transform: none !important;
  }
</style>

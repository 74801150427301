import { hfapiAxios } from "hfapi"
import store from "../store"
import { auth0 } from "@/lib/auth0";

/**
 * @type {import('axios').AxiosInstance}
 */
const axios = hfapiAxios

const host = process.env['VUE_APP_HFAPI_HOST'];

export default function () {
  if (host === undefined) throw new Error("VUE_APP_HFAPI_HOST is not defined")
  axios.defaults.baseURL = host

  axios.interceptors.request.use(async request => {
    if (await auth0.isAuthenticated()){
      const accessToken = await auth0.getTokenSilently();
      request.headers.set("Authorization", `Bearer ${accessToken}`)
      request.headers.set("page-language-id", store.state.page_language_id)
    }
    return request
  })
}

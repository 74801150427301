import Vue from 'vue'
import Vuex from 'vuex'
import PersistedState from 'vuex-persistedstate'
import { LANGUAGE_SYMBOL_2_ID } from "@/config/constants.js";

import user from './modules/user'
import flash from './modules/flash'
import master from './modules/master'
import gac from './modules/gac'
import { auth0 } from '@/lib/auth0';


Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    user, flash, master, gac,
  },
  plugins: [PersistedState({
    key: 'jtj',
    paths: ['page_language_id', 'gac'],
    storage: window.localStorage
  })],
  state: {
    page_language_id: "",
    isSignIn: false,
  },
  mutations: {
    setPageLang(state, symbolOrId){
      if(Number(symbolOrId)){
        // Idの時
        state.page_language_id = symbolOrId;
      } else {
        // symbolの時
        state.page_language_id = LANGUAGE_SYMBOL_2_ID[symbolOrId];
      }
    },
    setIsSignIn(state, isSignIn){
      state.isSignIn = isSignIn;
    }
  },
  actions: {
    async updateSignInStatus({commit}){
      const isSignIn = await auth0.isAuthenticated();
      commit('setIsSignIn', isSignIn);
    }
  }
})

export default store

// @ts-nocheck
import { auth0Config } from "@/lib/auth0";
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default new Router({
  routes: [
    // public
    { path: "/terms_of_service", name: "TermsOfServicePage", component: () => import("@/components/pages/public/TermsOfServicePage"), meta: { auth: "public", redirect_type: "none" } },
    { path: "/", name: "TopPage", component: () => import("@/components/pages/public/TopPage"), meta: { auth: "not_log_in", redirect_type: "none" } },
    { path: "/sign_up", name: "SignUpPage", component: () => import("@/components/pages/public/SignUpPage"), meta: { auth: "not_log_in", redirect_type: "take_over" } },
    { path: "/log_in", name: "LogInPage", component: () => import("@/components/pages/public/LogInPage"), meta: { auth: "not_log_in", redirect_type: "take_over" } },
    { path: auth0Config.redirectUri, name: "Auth0CallbackPage", component: () => import("@/components/pages/public/Auth0CallbackPage"), meta: { auth: "public", redirect_type: "none" } },
    { path: "/deprecated-email-error", name: "Auth0DeprecatedEmailErrorPage", component: () => import("@/components/pages/public/Auth0DeprecatedEmailErrorPage"), meta: { auth: "public", redirect_type: "none" } },
    { path: "/set_gid_as_hf_member", name: "SetGidAsHfMemberPage", component: () => import("@/components/pages/public/SetGidAsHfMemberPage"), meta: { auth: "public", redirect_type: "take_over" } },
    // users
    { path: "/initialize_profile", name: "InitializeProfilePage", component: () => import("@/components/pages/users/InitializeProfilePage"), meta: { auth: "log_in", redirect_type: "take_over" } },
    { path: "/profile", name: "ProfilePage", component: () => import("@/components/pages/users/ProfilePage"), meta: { auth: "log_in", redirect_type: "take_over" } },
    // recruitments
    { path: "/recruitments/:id", name: "RecruitmentShowPage", component: () => import("@/components/pages/recruitments/RecruitmentShowPage"), meta: { auth: "public", redirect_type: "self" } },
    { path: "/recruitments", name: "RecruitmentsSearchPage", component: () => import("@/components/pages/recruitments/RecruitmentsSearchPage"), meta: { auth: "log_in", redirect_type: "none" } },
    { path: "/recruitments/:id/apply", name: "SimpleApplyPage", component: () => import("@/components/pages/recruitments/SimpleApplyPage"), meta: { auth: "public", redirect_type: "self" } },
    // applications
    { path: "/applications/:id/resume/edit", name: "ApplicationResumeEditPage", component: () => import("@/components/pages/applications/ApplicationResumeEditPage"), meta: { auth: "own_application", redirect_type: "self" } },
    { path: "/applications/:id/resume/edit_profile", name: "ApplicationResumeEditProfilePage", component: () => import("@/components/pages/applications/ApplicationResumeEditProfilePage"), meta: { auth: "own_application", redirect_type: "self" } },
    { path: "/applications/:id/resume/edit_all", name: "ApplicationResumeEditAllPage", component: () => import("@/components/pages/applications/ApplicationResumeEditAllPage"), meta: { auth: "own_application", redirect_type: "self" } },
    { path: "/applications/:id/resume/confirm", name: "ApplicationResumeConfirmPage", component: () => import("@/components/pages/applications/ApplicationResumeConfirmPage"), meta: { auth: "own_application", redirect_type: "self" } },
    { path: "/applications/:id/resume/sent", name: "ApplicationResumeSentPage", component: () => import("@/components/pages/applications/ApplicationResumeSentPage"), meta: { auth: "own_application", redirect_type: "none" } },
    // articles
    { path: "/articles/:id", name: "ArticleShowPage", component: () => import("@/components/pages/articles/ArticleShowPage"), meta: { auth: "public", redirect_type: "self" } },
    { path: "/articles", name: "ArticlesIndexPage", component: () => import("@/components/pages/articles/ArticlesIndexPage"), meta: { auth: "public", redirect_type: "none" } },
    // events
    { path: "/events/:id", name: "EventShowPage", component: () => import("@/components/pages/events/EventShowPage"), meta: { auth: "public", redirect_type: "self" } },
    { path: "/events", name: "EventsIndexPage", component: () => import("@/components/pages/events/EventsIndexPage"), meta: { auth: "public", redirect_type: "none" } },
    { path: "/events/:id/apply", name: "EventApplyPage", component: () => import("@/components/pages/events/EventApplyPage"), meta: { auth: "public", redirect_type: "self" } },
    { path: "/events/:id/apply_complete", name: "EventApplyCompletePage", component: () => import("@/components/pages/events/EventApplyCompletePage"), meta: { auth: "public", redirect_type: "none" } },
    { path: "/events/:id/cancell", name: "EventApplicationCancellPage", component: () => import("@/components/pages/events/EventApplicationCancellPage"), meta: { auth: "public", redirect_type: "self" } },
    // courses
    { path: "/courses", name: "CourseLandingPage", component: () => import("@/components/pages/courses/CourseLandingPage"), meta: { auth: "public", redirect_type: "self" } },
    { path: "/courses/:id", name: "CourseLessonsIndexPage", component: () => import("@/components/pages/courses/CourseLessonsIndexPage"), meta: { auth: "limited", redirect_type: "self" } },
    { path: "/courses/:course_id/lesson/:lesson_id", name: "LessonPage", component: () => import("@/components/pages/courses/LessonPage"), meta: { auth: "limited", redirect_type: "self" } },
  ],

  // スクロール時にトップへ
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition != null) { return savedPosition }
    else { return { x: 0, y: -100 } }
  }
});
